<template>
  <div id="app">
    <template v-if="token">
      <MediaQueryProvider :queries="$options.queries">
        <MatchMedia v-slot="{ mobileSize }">
          <notifications
            group="error"
            :position="!mobileSize ? 'top right' : 'top center'"
          />
          <notifications
            group="success"
            :position="!mobileSize ? 'top right' : 'top center'"
          />
          <notifications group="warning" position="top center" />
        </MatchMedia>
        <router-view></router-view>
        <transition name="fade">
          <div class="loading-spinner" v-if="$store.state.loading">
            <div
              class="loading-spinner-div"
              style="margin-top:auto;height:100%"
            >
              <fulfilling-bouncing-circle-spinner
                :animation-duration="4000"
                :size="60"
                :color="colorLoading"
              />
            </div>
          </div>
        </transition>
      </MediaQueryProvider>
    </template>
    <!-- <h1 style="padding: 5%; text-align:center">سایت در حال بروز رسانی است لطفا اندکی بعد تلاش کنید</h1> -->
  </div>
</template>
<script>
// افزودن کامپوننت زیر برای  حالت موبایلی و استفاده از آن در کامپوننت های دیگر
import { MediaQueryProvider } from "vue-component-media-queries";
import { MatchMedia } from "vue-component-media-queries";
import config from "@/config";
const queries = {
  mobileSize: "(max-width: 768px)",
  xxSmall: "(max-width: 420px)",
  xSmall: "(max-width: 576px)",
  small: "(min-width: 576px)",
  medium: "(max-width: 768px)",
  large: "(max-width: 992px)",
  slarge: "(max-width: 1040px)",
  xLarge: "(min-width: 992px)",
  xxLarge: "(min-width: 1200px)",
  xxsLarge: "(min-width: 1290px)",
  xxxLarge: "(min-width: 1399px)",
};
export default {
  queries,
  components: {
    MediaQueryProvider,
    MatchMedia,
  },
  created() {
    // /v1/front/get_website_token
    let formData = new FormData();
    formData.append("base_url", window.location.hostname); 
    // formData.append("base_url", "sajadcameron.ir"); 
    this.$axios.post("/front/get_website_token", formData).then((res) => {
      this.token = res.data.data.website_token;
      this.$store.commit("front/setCustomer_id", res.data.data.website_token);
      document.cookie = `customer_id=${res.data.data.website_token}`;
    });
  },
  data() {
    return {
      colorLoading: config.colorLoading,
      token: "",
    };
  },
};
</script>
<style></style>
