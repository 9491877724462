import { setCookie, deleteCookie } from "@/libraries/VueMethods";
import cloneDeep from 'lodash/cloneDeep'
import cart from "@/parts/Front/components/cart/cart";
import {router} from '@/main'
import axios from "axios";


const state = {
    loginStatus: false,
    fullName: null,
    extraDiscountOnCard:0,
};
const getters = {
    // این تابع برای دریافت استاتوس لاگین است
    // از این تابع میتوانید هرجا که نیاز داشتید لاگین بودن کاربر را چک کنید استفاده کنید
    getLoginStatus(state) {
        return state.loginStatus;
    },
    getExtraDiscountOnCard(state){
        return state.extraDiscountOnCard
    }
};
const mutations = {
    setExtraDiscountOnCard(state,amount){
        state.extraDiscountOnCard=Math.ceil(amount)
    },
    // تابع ست کردن استاتوس برای لاگین بودن کاربر
    // این تابع در مانتد کامپوننت های هدر و رسپ لاگین ودال صدا زده میشود
    // این تابع به هنگام لوگ اوت با مقدار فالس صدا زده میشود
    setLoginStatus(state, status) {
        state.loginStatus = Boolean(status);
        // حذف کردن یوزر و پاک کردن سبد خرید
        let homeData = this.state.front[1].homeData;
        if (status == false && homeData) {
            delete axios.defaults.headers.common["Authorization"]
            deleteCookie("Authorization");
            let authRoutes = [
                'callback',
                'order',
                'user-panel'
            ];
            authRoutes.every(routeUrl => {
                if (router.currentRoute.path.includes(routeUrl)) {
                    router.push('/')
                    return false
                }
                return true
            })
            // Reset cart priority
            this.state.front[1].currentCartPriority = -1
            homeData.user.login = false;
            homeData.user.user = null;
            homeData.user.notifications = null;
            homeData.user.cart = new cart(this._vm, [], 'cookie');
        } else if (homeData && typeof status === 'object') {
            let carts = status.carts ? status.carts : status.cart;
            // اگر یوزر رو بدی ست میکنه
            let user = cloneDeep(homeData.user)
            user.login = true;
            user.user = status.user;
            user.cart = new cart(this._vm, carts, 'server');
            user.notifications = status.notifications;
            homeData.user = user;
        }
    },
    setProfileImage(state, image) {
        let homeData = this.state.front[1].homeData;
        let userDetail = this.state.front[7].userDetail;
        let user = cloneDeep(homeData.user); // For reactivity
        user.user.image = image;
        homeData.user = user;

        userDetail.image = image;
    },
    // Set token in cookie and axios
    setToken(state, accessToken) {
        setCookie(
            "Authorization",
            `Bearer ${accessToken}`,
            365
        );
        this._vm.$axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }

};
const actions = {
    async deleteAddress({ commit }, payLoad) {
        let id = payLoad.id
        let willDelete = await window.swal({
            title: "آدرس حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"],
        })

        if (willDelete) {
            return await this._vm.$axios.delete(`user/addresses/${id}`)
        }
    },

};
export default {
    state,
    getters,
    mutations,
    actions
}
